.lens_total {
    color: #008ed6;
}

.lens_nulo {
    color: #f74a4a;
}

.lens_parcial {
    color: orange;
}

.browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.thumbnail {
    height: 200px;
}

.thumbnail img.pull-right {
    width: 50px;
}

.col-horas {
    width: 3%;
    font-size: 12px;
    text-align: right;
    padding: 0px !Important;
    border: none !Important;
}

.col-horas>div {
    line-height: 20px;
}

.col-no-horas {
    width: 13%;
    padding: 0px !Important;
    border: none !Important;
}

.acme-navbar-basic {
    min-height: 83px;
}

.main-view {}

.noerror>.md-errors-spacer {
    min-height: 0px;
}

.md-button.md-primary {
    border-radius: 5px;
}

.md-button.md-primary.md-raised {
    background-color: #008ed6;
    color: white;
}

.md-button.md-primary.md-raised:not([disabled]):hover {
    background-color: #1c5792;
}

.md-button.md-primary.md-raised[disabled] {
    color: rgba(0, 0, 0, 0.26);
    background-color: #aaaaaa;
}

.md-button.md-primary.md-fab {
    background-color: #008ed6;
    color: white;
}

.md-button.md-primary.md-fab:not([disabled]):hover {
    background-color: #1c5792;
}

.md-button.md-fab[disabled] {
    color: rgba(0, 0, 0, 0.26);
    background-color: #aaaaaa;
}

.md-button.md-fab {
    background-color: #008ed6;
    color: white;
}

.md-button.md-fab:not([disabled]):hover {
    background-color: #1c5792;
}

.md-button.md-primary.md-fab[disabled] {
    color: rgba(0, 0, 0, 0.26);
    background-color: #aaaaaa;
}

.md-subheader.md-primary {
    color: #008ed6;
    ;
}

.md-button.md-fab.selected {
    background-color: #1c5792;
}

input.search {
    background-color: #eee;
    border-radius: 20px;
    padding: 0px 10px;
    border: 0px;
}
md-input-container {
    margin: 9px 0px;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input {
    /*border-color: #1c5792;*/
    border-color: unset;
    border-width: 0px;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-select {
    border-color: #008ed6;
}

md-card {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
}

md-whiteframe {
    border-radius: 5px;
}

.md-whiteframe-4dp {
    border-radius: 5px;
}

md-progress-linear .md-bar {
    background-color: #0083d6;
}

.mainfade.ng-enter {
    transition: 5s linear all;
    opacity: 0;
}

.mainfade.ng-enter.ng-enter-active {
    opacity: 1;
}

md-toast {
    z-index: 100000;
}

md-checkbox.md-default-theme.md-checked .md-ink-ripple,
md-checkbox.md-checked .md-ink-ripple {
    color: #0083d6;
}

md-checkbox.md-default-theme.md-checked .md-icon,
md-checkbox.md-checked .md-icon {
    background-color: #0083d6;
}

.flexbutton {
    width: 100%;
}

md-content.md-default-theme,
md-content {
    background-color: white;
}

md-toolbar.md-default-theme:not(.md-menu-toolbar),
md-toolbar:not(.md-menu-toolbar) {
    background-color: #0083d6;
    color: white;
}

md-progress-circular.md-default-theme path,
md-progress-circular path {
    stroke: #0083d6;
}

h2 {
    font-weight: 200;
}

md-autocomplete-wrap {
    border-radius: 20px;
    background-color: #eee;
    box-shadow: none;
}
table.accesos>tbody>tr {
	height: 25px !Important;
}

table.accesos>tbody>tr>td.md-cell {
	font-size: 12px !Important;
}

table.md-table.md-row-select td.md-cell:first-child,
table.md-table.md-row-select th.md-column:first-child {
	padding: 0 0 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(2),
table.md-table.md-row-select th.md-column:nth-child(2) {
	padding: 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2),
table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
	padding: 0 5px 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:first-child,
table.md-table:not(.md-row-select) th.md-column:first-child {
	padding: 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
	padding: 0 5px 0 5px;
}

.correcto {
	color: #008ed6;
}

.nopermitido {
	color: #f74a4a;
}

.parcial {
	color: orange;
}
.logo{
  max-width: 100%;
  height: auto;
}

.microappenmain {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  margin-left: 8px;
}

.microappcard {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 5px;
}

.empleado {
  background-color: rgb(144, 202, 249);
  font-size: 12px;
}

md-grid-tile div md-grid-tile-footer {
  height: 20%;
}

.management md-input-container {
	margin: 18px 0px 0px 0px;
}

.management md-input-container .md-errors-spacer {
	min-height: 0px;
}

.management .md-subheader-inner {
	padding: 0px 0px 0px 8px;
}

.management h3 {
	margin: 16px 0px 0px 0px;
}

.md-table-pagination md-icon {
	color: #0083d6;
}

.md-table-pagination .label {
	color: rgba(0, 0, 0, 0.54);
	font-size: 12px;
}

.md-table-pagination .md-select-icon {
	color: #0083d6;
}

.md-table-pagination .md-select-value {
	color: #0083d6;
}

table.md-table tbody.md-body>tr.md-row {
	height: 30px;
}



.acme-evento {
  position: absolute;
  background-color: deepskyblue;
  top: 0px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 8px;
  color: white;
  text-align: center;
}

.acme-evento21 {
  width: 95%;
}

.acme-evento11 {
  width: 95%;
}

.acme-evento12 {
  width: 45%;
}

.acme-evento22 {
  left: 50%;
  width: 45%;
}

.acme-evento > .titulo {
  text-align: center;
  border-radius: 5px;
  border-style: solid;
  padding-right: 0px;
  margin-right: 10px;
  margin-left: 5px;
  margin: 5px;
  color: white;
  line-height: 15px;
  border: 0px;
}

#footerDeportistas {
	padding: 0px 16px 0px 16px;
}


.layer-container {
  position: relative;
  float: left;
  width: 100%;
}

.layer {
  height: 70px;
  width: 89%;
  float: left;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  margin-left: 12px;
  margin-right: 12px;
}

.pasado {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 100%;
  background-color: lightgrey;
}

.direction-button {}

.direction-button > button {
  height: 70px;
  border: none;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.heartbeat {
  display: block;

  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.login {
	background-color: white;
}

.loginerror {
	color: white;
	background-color: #dd2c00;
	border-radius: 2px;
	text-align: center;
	font-weight: bold;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
md-sidenav {
  overflow: hidden;
  bottom: unset;
}

md-item-content {
  position: relative;
}

.notupdated {
  color: #dd0000;
}

.navbaractive {
  background: #008ed6;
  color: white;
}

.navbarpadreopen {
}

.navbardisabled {
  background-color: lightgrey;
}

.withDescriptions {
  border-bottom: solid 1px #bbbbbb;
  padding-right: 50px;
  border-radius: 0px !important;
}

.insetIco {
  padding: 4px;
}

.httpspinner {
  top: 0px;
  position: fixed;
  z-index: 10000;
}

.viewTrue {
    color: #008ed6;
}
span.uploader {
    border-radius: 5px;
    border: 1px dashed;
    color: #0a0a0a;
    text-align: center;
    font-size: 1em;
    padding: 5px;
    margin: 5px 0;
    width: 100%;
}

div.files {
    border-radius: 5px;
    border: 1px dashed;
    color: #0a0a0a;
    font-size: 1em;
    padding: 5px;
    margin: 5px 0;
    width: 100%;
}
span.uploader.up_ {
    /* transition: 1s;
    -webkit-transition: 1s; */
    background-color: #eee;
}

span.uploader.up_dragging {
    opacity: 0.6;
    background-color: #008ed6;
}

span.uploader.up_uploading {
    background-color: #008ed6;
    color: #fff;
}



.selectwhite md-select-value {
	color: white !Important;
}

.selectwhite md-select-value .md-select-icon {
	color: white;
}



#contabilizado {
    font-size: 11px;
}

#contabilizado .md-virtual-repeat-scroller{
    overflow-y: scroll;
}

.contabilizadoRow {
    height: 24px;
    width: 100%;
    padding: 0px;
}

.contabilizadoRow:hover {
    /* background-color:rgb(0, 142, 214,0.7) !Important; */
    font-weight: bold;
}

.contabilizadoRow span{
    padding: 0px 5px;
}
.intransit {
    background: #b5b5b5;
}

md-grid-tile-header figcaption {
    width: 100%;
    text-align: center;
}

md-grid-tile-footer figcaption {
    width: 100%;
    text-align: center;
}

.nuevo {
    margin: 0px 8px 16px 8px;
    padding: 10px;
    height: 20%;
    background-color: #008ed6;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.8vw;
    font-weight: 200;
}
#extracto {
    font-size: 11px;
}

#extracto .md-virtual-repeat-scroller{
    overflow-y: scroll;
}

.extractoRow {
    height: 24px;
    width: 100%;
    padding: 0px;
}

.extractoRow:hover {
    /* background-color:rgb(0, 142, 214,0.7) !Important; */
    font-weight: bold;
}

.extractoRow span{
    padding: 0px 5px;
}
#fichero {
    font-size: 11px;
}

#fichero .md-virtual-repeat-scroller{
    overflow-y: scroll;
}

.ficheroRow {
    height: 24px;
    width: 100%;
    padding: 0px;
}

.ficheroRow:hover {
    /* background-color:rgb(0, 142, 214,0.7) !Important; */
    font-weight: bold;
}

.ficheroRow span{
    padding: 0px 5px;
}
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.thumbnail {
  height: 200px;
}

.thumbnail img.pull-right {
  width: 50px;
}

.col-horas {
  width: 3%;
  font-size: 12px;
  text-align: right;
  padding: 0px !Important;
  border: none !Important;
}

.col-horas>div {
  line-height: 20px;
}

.col-no-horas {
  width: 13%;
  padding: 0px !Important;
  border: none !Important;
}

.dia {}

md-input-container {
	padding-bottom: 4px;
}

.md-subheader-content>h3 {
	margin: 0px;
}

.md-subheader-inner {
	padding-bottom: 4px !Important;
	padding-left: 8px !Important;
}

md-content {
	/*  padding-top: 0px !Important;*/
	/*  background-color: #eee;*/
}

md-tabs.md-default-theme .md-tab.md-active,
md-tabs .md-tab.md-active,
md-tabs.md-default-theme .md-tab.md-active md-icon,
md-tabs .md-tab.md-active md-icon,
md-tabs.md-default-theme .md-tab.md-focused,
md-tabs .md-tab.md-focused,
md-tabs.md-default-theme .md-tab.md-focused md-icon,
md-tabs .md-tab.md-focused md-icon {
	color: rgb(16, 108, 200) !Important;
}

md-tabs.md-default-theme .md-tab,
md-tabs .md-tab {
	color: rgba(0, 0, 0, 0.54) !Important;
}

md-pagination-wrapper {
	width: 100% !Important;
}

.cliente-image {
	margin: 10px;
}

.busquedafila {
	line-height: 20px;
	padding: 0px 5px 0px 5px;
	align-items: center;
}

.busquedacodcliente {
	font-size: 12px;
	font-weight: bold;
}

.busquedaidcentro {
	padding: 2px 5px;
	font-size: 12px;
	background-color: white;
	border-radius: 5px;
	text-align: center;
	color: #008ed6;
	font-weight: bold;
}

.busquedaidcentrolistado {
	padding: 2px 5px;
	font-size: 12px;
	background-color: #008ed6;
	border-radius: 5px;
	text-align: center;
	color: white;
	font-weight: bold;
}

.busquedanombre {
	text-align: left;
	font-size: 16px;
	color: #232323;
}

.clientetabActiva {
	width: 80px;
	padding: 0px 10px;
	font-size: 12px;
	background-color: white;
	border-radius: 5px;
	color: #008ed6;
	margin: 0px 5px;
}

.clientetabNoactiva {
	font-size: 12px;
	width: 80px;
	padding: 0px 10px;
	margin: 0px 5px;
}

#main>.clienteenmain {
	padding-top: 16px;
	padding-right: 16px;
	padding-left: 8px;
}

.tag {
	border-radius: 20px;
	margin: 5px;
	padding: 5px 15px;
	color: #333;
	background-color: #CCC;
}

.tagChild {
	border-radius: 10px;
	margin: 0px 5px;
	padding: 0px 15px;
	color: #333;
	background-color: #CCC;
}

.tagPendiente {
	background-color: #dd0000;
	color: white;
}

.tagOk {
	background-color: #008ed6;
	color: white;
}

.tagOkChild {
	background-color: #008ed6;
	color: white;
	border: 1px solid white;
}

.tagOk>span:not(:last-child)::after {
	content: ", ";
	margin-right: 5px;
}

.tagOk>span:first-child::after {
	content: ": ";
	margin-right: 5px;
}
/* Estado abierta */

table.tareas>tbody>tr {
	height: 25px !Important;
}

table.tareas>tbody>tr>td.md-cell {
	font-size: 12px !Important;
}

table.md-table.md-row-select td.md-cell:first-child,
table.md-table.md-row-select th.md-column:first-child {
	padding: 0 0 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(2),
table.md-table.md-row-select th.md-column:nth-child(2) {
	padding: 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2),
table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
	padding: 0 5px 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:first-child,
table.md-table:not(.md-row-select) th.md-column:first-child {
	padding: 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
	padding: 0 5px 0 5px;
}

.cerrada {
	border-left: #dd2c00 5px solid;
}

.abierta {
	border-left: #008ed6 5px solid;
}

tr.child {
	background-color: darkgrey;
}

.estadochild {
	visibility: hidden;
}

.tareatextonowrap {
	font-size: 0.8vw;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.tareaasunto {
	font-size: 14px;
	font-weight: bold;
	color: dimgrey;
	padding-bottom: 5px;
	padding-top: 7px;
}

.tareaassignedto {
	font-size: 12px;
	color: gray;
	padding-right: 10px;
}

.tarealineas {
	font-size: 12px;
	color: gray;
	padding-left: 10px;
}

.tareafins {
	padding-top: 7px;
	padding-bottom: 0px;
	font-size: 12px;
	color: gray;
}

.tareafact {
	padding-top: 0px;
	padding-bottom: 7px;
	font-size: 12px;
	color: gray;
}

.noleido {
	background-color: darkgrey;
}
.fichajeI {
	background: linear-gradient(#9895967d, white);
	border-radius: 50px 15px 0px 0px;
}

.fichajeD {
	background: linear-gradient(#008ed6, white);
	border-radius: 50px 15px 0px 0px;
}

.fichajeF {
	background: linear-gradient(#dd2c00, white);
	border-radius: 50px 15px 0px 0px;
}
.signature>canvas {
	border: black solid 2px;
}

.Pagohecho {
	color: #008ed6;
}

.Pagopendiente {
	color: #f74a4a;
}

.noerror>.md-errors-spacer {
	min-height: 0px;
}
.intransit {
    background: #b5b5b5;
}

md-grid-tile-header figcaption {
    width: 100%;
    text-align: center;
}

md-grid-tile-footer figcaption {
    width: 100%;
    text-align: center;
}

.nuevo {
    margin: 0px 8px 16px 8px;
    padding: 10px;
    height: 20%;
    background-color: #008ed6;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.8vw;
    font-weight: 200;
}


.reportItem {
    height: 10%;
    padding: 0px 4px 8px 4px;
}

.reportItem>md-whiteframe {
    background-color: #008ed6;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.8vw;
    font-weight: 200;
}

.h {
	height: 100%;
}

.a {
	border: 5px solid #008ed6;
	margin: 5px;
}

.a md-card {
	border: 2px solid darkblue;
}


.ticketacciones {
	margin-top: 0px;
}

#accionesTicket {
	padding-top: 0px;
}

.activa {
	background-color: #1c5792 !Important;
}

.showticket {
	min-height: 550px;
}

.operacionpendiente {
	color: white !Important;
	background-color: #f74a4a !Important;
}

.listaAjuste > :first-child{
	margin-left: 0px;
}

.listaAjuste > :last-child{
	margin-right: 0px;
}
.caja {
    color: white;
    font-size: 350px;
    font-weight: bold;
    background-color: #e6e237;
}

.walletcont {
    margin: 5px 0px 5px 0px;
    min-width: 18%;
    color: white;
    padding: 0px 10px;
}

.walletheader {
    font-size: 1vw;
    text-align: center;
    ;
}

.walletcantidad {
    font-size: 1vw;
    font-weight: bold;
    text-align: center;
}

.walletbutton {
    font-size: 1vw;
    font-weight: bold;
}

.wallettotal {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.M {
    background-color: #008ed6;
}

.B {
    background-color: #1c5792;
}

.X {
    background-color: #008ed6;
}

.intransit {
    background: #b5b5b5;
}

md-grid-tile-header figcaption {
    width: 100%;
    text-align: center;
}

md-grid-tile-footer figcaption {
    width: 100%;
    text-align: center;
}

.nuevo {
    margin: 0px 8px 16px 8px;
    padding: 10px;
    height: 20%;
    background-color: #008ed6;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.8vw;
    font-weight: 200;
}

.body-celebracion {
  overflow: hidden;
}

.selected-bono {
  float: left;
  margin-left: 10px;
}

.reject {
  text-decoration: line-through;
}

.linea {
  margin-bottom: 1px;
}

.deportista {
	background-color: white;
	color: #333;
	margin: 2px;
}

.deportistaDesconocido {
	background-color: white;
	color: #333;
	margin: 2px;
}

.deportistaTexto {
	font-size: 0.75vw;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	height: 17px;
}

.aviso {
	color: #dd0000;
}

@keyframes showUp {
	from {
		opacity: 0;
		height: 0px;
		bottom: 0px;
		margin-left: 0px;
	}
	to {
		opacity: 1;
		height: 200px;
		bottom: 60px;
		margin-left: 60px;
	}
}

@keyframes hideDown {
	from {
		opacity: 1;
		height: 200px;
		bottom: 60px;
		margin-left: 60px;
	}
	to {
		opacity: 0;
		height: 0px;
		bottom: 0px;
		margin-left: 0px;
	}
}

.zoom {
	animation-name: showUp, hideDown;
	animation-fill-mode: forwards;
	animation-duration: 1s;
	animation-timing-function: ease-in;
	animation-iteration-count: 1;
	animation-delay: 0s, 3s;
}

.zoomOver {
	animation-name: showUp;
	animation-fill-mode: forwards;
	animation-duration: 0.5s;
	animation-timing-function: ease-in;
	animation-iteration-count: 1;
	animation-delay: 0s;
}
.element {
  margin: 5px;
  display: inline-block;
  height: 60px;
  width: 12%;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-color: lightgrey;
  padding: 8px;
  padding-top: 5px;
  color: white;
  text-align: center;
  font-size: 13px;
  position: relative;
  min-width: 100px;
}

.cerrar {
  position: absolute;
  bottom: 5px;
  left: 5px;
  padding: 0px;
  cursor: none;
  font-size: 19px;
}

.titulo {
  font-weight: bold;
}

.horario {
  font-size: 11px;
/*  font-weight: lighter;*/
  padding-left: 15px;
}

.proxima {}

.no-proxima {}


.pickingnulo {
	background-color: #f74a4a;
}

.pickingparcial {
	background-color: orange;
}

.pickingtotal {
	background-color: #008ed6;
}

.udsnulo {
	color: #f74a4a;
}

.udsparcial {
	color: orange;
}

.udstotal {
	color: #008ed6;
}

.pickingpendiente {
	background-color: gray;
}

.picker{
	font-size: 14px;
	font-weight: bold;
	margin:5px;
	background-color: #CCC;
	color: white;
	min-height: 40px;
	text-align: center;
}

.pickerSel{
	background-color: #008ed6;
}
.contratoActivo {
    background: linear-gradient(180deg, #008ed6 32px, white 32px);
}

.contratoInactivo {
    background: linear-gradient(180deg, #bbbbbb 32px, white 32px);
}

.contratoActivo>.contratoOpt {
    background-color: #008ed6;
}

.contratoInactivo>.contratoOpt {
    background-color: #bbbbbb;
}



table.facturacion>tbody>tr {
	height: 25px !Important;
}

table.facturacion>tbody>tr>td.md-cell {
	font-size: 12px !Important;
}

table.md-table.md-row-select td.md-cell:first-child,
table.md-table.md-row-select th.md-column:first-child {
	padding: 0 0 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(2),
table.md-table.md-row-select th.md-column:nth-child(2) {
	padding: 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2),
table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
	padding: 0 5px 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:first-child,
table.md-table:not(.md-row-select) th.md-column:first-child {
	padding: 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
	padding: 0 5px 0 5px;
}

.factpagado {
	color: #008ed6;
}

.factpendiente {
	color: #f74a4a;
}

.factparcial {
	color: orange;
}

.factfuturo {
	color: gainsboro;
}

tr.child {
	background-color: darkgrey;
}
.valida {
	border-left: #008ed6 5px solid;
}

.novalida {
	border-left: #dd2c00 5px solid;
}

.pendiente {
	border-left: orange 5px solid;
}


.nuevaTarea {
    height: 45%;
}





.itemscontent {
	overflow-x: hidden;
}

.items {
	padding-left: 5px;
	padding-right: 5px;
}

.cuotaitem {
	font-size: 10px;
	color: white;
}







/*.operarioSel {
	margin-left: 0px !Important;
	margin-right: 0px !Important;
}
*/

.serviciosFilt {
	width: 100%;
	margin-bottom: 10px;
	margin-top: 10px;
}

.gridheader {
	background: transparent;
	/*font-size: 12px;*/
	font-weight: bold;
	padding-left: 5px;
	padding-right: 5px
}







/*.operarioSel {
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	width: 98%;
	margin: 5px;
}*/

.operarioSel {
	background-color: #008ed6 !Important;
}

.ticketitemsEmpleado {
	background-color: gray;
	color: white;
	border: 0px gray solid;
	border-radius: 10px;
	margin-bottom: 1px;
	padding: 0px 3px;
	text-align: center;
	margin: 2px 2px 2px 2px;
	width: 40px;
}
.Ppay {
	height: 50px;
}

.Pwallet {
	color: white;
	font-size: 30px;
	font-weight: bold;
}

.Pwalletheader {
	font-size: 2vw;
}

.Pwalletcantidad {
	font-size: 2vw;
	font-weight: bold;
	text-align: center;
}

.Pwalletbutton {
	font-size: 2vw;
	font-weight: bold;
}

.PM {
	background-color: coral;
}

.PB {
	background-color: #A514FF;
}
.idticket {
  font-size: 30px;
  font-weight: bold;
  text-align: right;
}

.fecha {
  font-size: 30px;
  font-weight: bold;
}

.cliente {
  font-size: 16px;
}

.total {
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}

.totalImp {
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}

.item {
  padding: 0px !important;
  min-height: 0px !important;
}

.itemImp {
  text-align: right;
  font-size: 11px;
}

.itemdescripcion {
  font-size: 11px;
  padding-left: 5px;
}

.itemnombreSub {
  font-size: 10px;
  padding-left: 5px;
}

.pagoImp {
  text-align: right;
  font-weight: bold;
}

.pagodescripcion {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
}

.operario {
  font-size: 10px;
}

.cabecera {
  border-radius: 5px 5px 0px 0px;
  background-color: #008ed6;
  padding: 0px 8px;
}

.pie {
  background-color: #008ed6;
  padding: 0px 8px;
}

.piepago {
}

.delete {
  color: rgb(63, 81, 181);
  margin-top: 0px;
}

.deletePago {
  color: #fff;
}

.ticketpreviewlinea {
  line-height: 15px;
}

.ticketpreviewlineacontainer {
  margin: 0px 0px 0px 0px;
  padding-left: 2px;
  padding-top: 2px;
}

.ticketpreviewlineacontainerPack {
  background-color: #008ed6;
  color: white;
  border-radius: 0px 5px 5px 0px;
}

.ticketpreviewlineacontainerChild {
  border-left: solid 2px #008ed6;
}

.ticketpreviewEmpleados {
  font-size: 10px;
  font-weight: bold;
}

.ticketpreviewEmpleado {
  background-color: gray;
  color: white;
  border: 1px gray solid;
  border-radius: 10px;
  margin-bottom: 1px;
  padding: 0px 3px;
  width: 100%;
  text-align: center;
}

.tarjeta {
  padding-left: 5px;
  font-size: 10px;
}

.itemsselected {
  background-color: #008ed6;
  color: white;
}
@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.blink {
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

.nuevoconcepto {
	height: 230px;
}

md-option.size12>.md-text {
	font-size: 12px;
}
.timelinedetail {
    position: absolute;
    top: 0px;
    height: 100%;
    border: 1px white solid;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
    font-size: small;
    padding-top: 3px;
}

.timelinecabecera {
    text-align: center;
    float: left;
    box-shadow: 0 2px 0px -1px gray;
    font-weight: 200;
}

md-tooltip.tt-multiline {
    height: auto;
}

.timelinedetailV {
    left: 0px;
    width: 100%;
    height: 100%;
    margin: 0px 10px 0px 0px;
    color: unset;
    /*border: 1px white solid;*/
    /*border-radius: 10px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);*/
    font-size: small;
    /*padding-top: 3px;
	margin-bottom: 2px;*/
}

.timelinecabeceraV {
    margin: 0px 10px 3px 0px;
    border-right: 2px solid grey;
    font-weight: bold;
    color: grey;
    font-size: 20px;
    height: 57px;
}

.timelinecabeceraH {
    margin: 0px 10px 3px 0px;
    border-bottom: 0px solid grey;
    font-weight: bold;
    color: grey;
    font-size: 24px;
    height: 50px;
    position: relative;
}
.comercialesHoy {
  font-weight: bold;
}

table.comercialesTable >tbody>tr {
  height: 25px !Important;
}

table.comercialesTable >tbody>tr>td.md-cell {
  font-size: 12px !Important;
}

table.md-table.md-row-select td.md-cell:first-child,
table.md-table.md-row-select th.md-column:first-child {
  padding: 0 0 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(2),
table.md-table.md-row-select th.md-column:nth-child(2) {
  padding: 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2),
table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
  padding: 0 5px 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:first-child,
table.md-table:not(.md-row-select) th.md-column:first-child {
  padding: 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
  padding: 0 5px 0 5px;
}


.facturacionHoy {
  font-weight: bold;
}

table.facturacionTable >tbody>tr {
  height: 25px !Important;
}

table.facturacionTable >tbody>tr>td.md-cell {
  font-size: 12px !Important;
}

table.md-table.md-row-select td.md-cell:first-child,
table.md-table.md-row-select th.md-column:first-child {
  padding: 0 0 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(2),
table.md-table.md-row-select th.md-column:nth-child(2) {
  padding: 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2),
table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
  padding: 0 5px 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:first-child,
table.md-table:not(.md-row-select) th.md-column:first-child {
  padding: 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
  padding: 0 5px 0 5px;
}

.NIVEL0 {
  padding-left: 0px !Important;
}

.NIVEL1 {
  padding-left: 10px !Important;
}

.NIVEL2 {
  padding-left: 20px !Important;
}

.NIVEL3 {
  padding-left: 30px !Important;
}

.NIVEL4 {
  padding-left: 40px !Important;
}

.totalfacturacion {
  font-weight: bold;
}


.Socios,
.altasSocios,
.bajasSocios {
	color: dodgerblue;
	font-size: 24px;
	font-weight: lighter;
	text-align: center;
}

.altasSocios {
	color: cornflowerblue;
}

.bajasSocios {
	color: #f74a4a;
}

.sociosHoy {
	font-weight: bold;
}

table.sociosTable>tbody>tr {
	height: 25px !Important;
}

table.sociosTable>tbody>tr>td.md-cell {
	font-size: 12px !Important;
}

table.md-table.md-row-select td.md-cell:first-child,
table.md-table.md-row-select th.md-column:first-child {
	padding: 0 0 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(2),
table.md-table.md-row-select th.md-column:nth-child(2) {
	padding: 0 5px;
}

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2),
table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
	padding: 0 5px 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:first-child,
table.md-table:not(.md-row-select) th.md-column:first-child {
	padding: 0 5px;
}

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
	padding: 0 5px 0 5px;
}

table.md-sociosTable th.md-column {
	color: rgba(0, 0, 0, 1);
	font-size: 15px;
	font-weight: lighter;
	white-space: nowrap;
}
.FAM_corte {
	background: lightblue;
}

.items_header {
	background: transparent;
	font-size: 12px;
	font-weight: bold;
	padding-bottom: 0px;
	padding-left: 5px;
	padding-right: 5px;
	text-align: center;
	width: 100%;
}

.items_precio {
	background: transparent;
	font-size: 30px;
	font-weight: bold;
	padding-bottom: 0px;
	padding-left: 5px;
	padding-right: 5px;
	text-align: center;
	width: 100%;
}

.items_caja {
	margin: 5px;
	width: calc(25% - 10px);
	/* min-height: 18%; */
	padding: 0px;
	background-color: #008ed6;
}

.items_cajaRow {
	margin: 5px;
	width: 100%;
	padding: 0px;
	background-color: #008ed6;
}

.items_masprecios {
	width: 100%;
	font-size: 10px;
	border: black solid 1px;
	padding: 0px;
}

.items_masprecios>span {
	text-align: center;
	border: black solid 1px;
	padding: 0px;
}

.itemslistacaja {
	height: 100%;
	color: white;
}

.itemslistacajaf1 {
	color: white;
	padding: 5px;
}

.itemslistacajaf2 {
	font-size: 1.3vw;
	text-align: center;
	padding: 5px;
}

.itemslistacajaf2Row {
	font-size: 1.8vw;
	text-align: center;
}

.itemslistacajaf1c1 {}

.itemslistacajaf1c2 {
	font-size: 15px;
}

.itemslistacajaf2c1 {
	font-size: 40px;
}

.itemslistacajaf1bc1 {}

.itemslistacajaf1b>b {
	font-size: 20px;
}

.itemslistacajaf1ac2 {
	font-size: 22px;
	font-weight: bold;
}

.itemslistacajaf1a {
	padding: 0px 5px 0px 5px;
	font-size: 22px;
	font-weight: bold;
	line-height: 90%;
}

.itemslistacajaf1bG {
	padding: 0px 5px 0px 5px;
	font-size: 16px;
}

.itemslistacajaf1bM {
	padding: 0px 5px 0px 5px;
	font-size: 14px;
}

.itemslistacajaf1bP {
	padding: 0px 5px 0px 5px;
	font-size: 10px;
}

.itemslistacajafadv {
	/*background: linear-gradient(rgb(210, 210, 210), rgba(255, 255, 255, 0));*/
	padding-top: 5px;
	margin -bottom: 5px;
	color: white;
	margin-left: 5px;
	margin-right: 5px;
	font-size: 12px
}

.itemsPie {
	background-color: red;
	color: white;
	font-weight: bold;
	border-radius: 0px 0px 5px 5px;
	font-size: 24px;
	text-align: left;
	padding: 0px 16px;
	width: 100%;
}

.itemsPieEncontrado {
	background-color: #008ed6;
}
.props_title {
    text-align: center;
    font-size: 40px;
    font-weight: 200;
    color: white;
}

.props_res_title {
    font-size: 20px;
    font-weight: 200;
    color: gray;
    margin: auto;
}

.props_resolve {
    margin: 5px;
    width: 200px;
    height: 100px;
}

.props_resolved {
    color: black;
}

.props_selected {
    font-weight: bold;
    color: #008ed6;
}

.props_caja {
	margin: 5px;
	width: 23%;
	padding: 5px;
	background-color: #008ed6;
	color: white;
}
